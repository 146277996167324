import React from "react";
import "./style.scss";
import { Fade } from "react-awesome-reveal";

const formatValue = (v) => {
  if (!v) return "";
  const text = String(v);
  if (text.trim() && +text <= 2) {
    return text + "%";
  } else {
    return text;
  }
};

const Column = ({
  background,
  classNameTitle,
  classNameDisplayNone1,
  classNameDisplayNone2,
  classNameDisplayNone3,
  title,
  standart,
  secondLineStandart,
  secondLinePrime,
  secondLinePrimePluse,
  prime,
  primePluse,
  index,
}) => {
  const duration = 600 + +(index + "0");
  return (
    <>
      <tr className={background}>
        <td className={`title ${classNameTitle}`}>
          <Fade duration={duration} direction="left" triggerOnce>
            {title}
          </Fade>
        </td>

        <td className={`secondLine ${classNameDisplayNone1}`}>
          {formatValue(standart)} <br /> <span>{secondLineStandart}</span>
        </td>
        <td className={`secondLine ${classNameDisplayNone2}`}>
          {formatValue(prime)} <br /> <span>{secondLinePrime}</span>
        </td>
        <td className={`secondLine ${classNameDisplayNone3}`}>
          {formatValue(primePluse)} <br /> <span>{secondLinePrimePluse}</span>
        </td>
      </tr>
    </>
  );
};

export default Column;
